import React, { useEffect, useState } from 'react';
import MovieImageComponent from './MovieImageComponent';
import DropDown from './DropDown';
import YearPagination from './YearPagination';
import ActorPagination from './ActorPagination';
import DirectorPagination from './DirectorPagination';
import GenrePagination from './GenrePagination';
import DecadePagination from './DecadePagination';
import './Movies.css';
import SearchMovie from './SearchMovie.js';
import SearchActor from './SearchActor';
import SearchDirector from './SearchDirector';
import './prevnextstyles.css';
import config from './config';
import ComingSoon from './ComingSoon';
export const moviesPerPage = 10; // Number of movie items to fetch

const Movies = (props) => {
  console.log("Movies lang=" + props.lang);
  console.log("Movies category=" + props.category);
  const lang = props.lang;
  const category = props.category;
  const [moviesToShow, setMoviesToShow] = useState([]);
  const [actorsToShow, setActorsToShow] = useState(null);
  const [genresToShow, setGenresToShow] = useState(null);
  const [directorsToShow, setDirectorsToShow] = useState(null);
  //AF const currentYear = new Date().getFullYear();
  const currentYear = 2024;
//  const currentDecade = "2020s";
  const currentDecade = 2000 + Math.floor((currentYear % 100)/10) * 10 + "s"; //ends up being 2020s
  console.log("currentDecade =" + currentDecade);
  const [isConditionMet, setIsConditionMet] = useState(false);

  const actorsPerPage = 6; // Number of actor items to fetch
  const directorsPerPage = 6; // Number of director items to fetch
  const genresPerPage = 6; // Number of genre items to fetch

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [actorsCurrentPage, setActorsCurrentPage] = useState(0);
  const [actorsTotalPageCount, setActorsTotalPageCount] = useState(0);

  const [genresCurrentPage, setGenresCurrentPage] = useState(0);
  const [genresTotalPageCount, setGenresTotalPageCount] = useState(0);

  const [directorsCurrentPage, setDirectorsCurrentPage] = useState(0);
  const [directorsTotalPageCount, setDirectorsTotalPageCount] = useState(0);
  //var totalPageCount = 0;

  const [loading, setLoading] = useState(true);

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedActorObj, setSelectedActorObj] = useState(null);
  const [selectedDirectorObj, setSelectedDirectorObj] = useState(null);
  const [selectedDecade, setSelectedDecade] = useState(currentDecade);
  const [selectedGenreObj, setSelectedGenreObj] = useState(null);

  const [actorSearched, setActorSearched] = useState(null);
  const [searchedActorName, setSearchedActorName] = useState('');

  const [directorSearched, setDirectorSearched] = useState(null);
  const [searchedDirectorsCurrentPage, setSearchedDirectorsCurrentPage] = useState(0);
  const [searchedDirectorName, setSearchedDirectorName] = useState('');

  const [searchedMovieName, setSearchedMovieName] = useState('');

  const handleActorSearch = (actorName, m_actorsCurrentPage) => {

      // The actors current page is set to first page when Search is clicked everytime
      setActorsCurrentPage(m_actorsCurrentPage);

      fetchActorSearch(actorName, m_actorsCurrentPage).then((result) => {
          setSearchedActorName(actorName);
          console.log("search actors count :" + result.count);
          const pgCnt = Math.ceil(result.count/actorsPerPage);
          setActorsTotalPageCount(pgCnt);
          if (pgCnt == 0) {
              setActorsCurrentPage(0);
              setMoviesToShow(null);
          }
          console.log("actorsCurrentPage :" + m_actorsCurrentPage);
          console.log("actorsTotalPageCount :" + pgCnt);
          console.log("result.data :" + result.data);
          setSelectedActorObj(null); //set to null so that first object from search gets selected for searching movies
          setActorsToShow(result.data);
          setActorSearched(actorName);
      });
  };

  const handleDirectorSearch = (directorName, m_directorsCurrentPage) => {

      // The directors current page is set to first page when Search is clicked everytime
      setDirectorsCurrentPage(m_directorsCurrentPage);

      fetchDirectorSearch(directorName, m_directorsCurrentPage).then((result) => {
          setSearchedDirectorName(directorName);
          console.log("search directors count :" + result.count);
          const pgCnt = Math.ceil(result.count/directorsPerPage);
          setDirectorsTotalPageCount(pgCnt);
          if (pgCnt == 0) {
              setDirectorsCurrentPage(0);
              setMoviesToShow(null);
          }
          console.log("directorsCurrentPage :" + m_directorsCurrentPage);
          console.log("directorsTotalPageCount :" + pgCnt);
          console.log("result.data :" + result.data);
          setDirectorsToShow(result.data);
          setSelectedDirectorObj(null);
          setDirectorSearched(directorName);
      });
  };

  const handleMovieSearch = (movieName, m_moviesCurrentPage) => {

      // The movies current page is set to first page when Search is clicked everytime
      setCurrentPage(m_moviesCurrentPage);
      setSearchedMovieName(movieName);

      fetchMovieSearch(movieName, m_moviesCurrentPage).then((result) => {
          console.log("search movies count :" + result.count);
          const pgCnt = Math.ceil(result.count/moviesPerPage);
          setTotalPageCount(pgCnt);
          if (pgCnt == 0) {
              setCurrentPage(0);
              setMoviesToShow(null);
          }
          console.log("moviesCurrentPage :" + m_moviesCurrentPage);
          console.log("moviesTotalPageCount :" + pgCnt);
          console.log("result.data :" + result.data);
          setMoviesToShow(result.data);
          //TBD: setSelectedActorObj(null);
      });
  };

  const handlePageChange = (newPage) => {
    if ( (searchedMovieName != null) && (searchedMovieName.length > 0) ) {
      handleMovieSearch(searchedMovieName, newPage);
    } else {
      setCurrentPage(newPage);
    }
  };

  const clearActorSearch = () => {
    console.log("Inside clearActorSearch");
    const firstPage = 0;
    setActorSearched(null);
    setSearchedActorName(null);
    setActorsToShow(null);
    setActorsCurrentPage(firstPage);
    if (selectedActorObj != null) {
      setSelectedActorObj(null);
    } else {
      setSelectedActorObj(''); // this is added to trigger useEffect if selectedActorObj is already set to null
    }
  };

  const handlePopularGenres = () => {
    console.log("Inside handlePopularGenres");
    const firstPage = 0;
    setGenresToShow(null);
    setGenresCurrentPage(firstPage);
    if (selectedGenreObj != null) {
      setSelectedGenreObj(null);
    } else {
      setSelectedGenreObj(''); // this is added to trigger useEffect if selectedGenreObj is already set to null
    }
  };

  const clearDirectorSearch = () => {
    console.log("Inside clearDirectorSearch");
    const firstPage = 0;
    setDirectorSearched(null);
    setSearchedDirectorName(null);
    setDirectorsToShow(null);
    setDirectorsCurrentPage(firstPage);
    if (selectedDirectorObj != null) {
      setSelectedDirectorObj(null);
    } else {
      setSelectedDirectorObj(''); // this is added to trigger useEffect if selectedDirectorrObj is already set to null
    }
  };

  const clearMovieSearch = () => {
    console.log("Inside clearMovieSearch");
    const firstPage = 0;
    const year = 0; // year = 0 means latest movies
    setSearchedMovieName(null);
    fetchMoviesByYear(year, firstPage);
  };

  const fetchMoviesByYear = (year, page) => {
    fetchMovies(year, page).then((result) => {
      // The following will concatenate the new data to the previous data
      //setData((prevData) => [...prevData, ...result]);
      console.log("count :" + result.count);
      const pgCnt = Math.ceil(result.count/moviesPerPage);
      console.log("pgCnt in fetchMovies :" + pgCnt);
      setTotalPageCount(pgCnt);
      console.log("currentPage :" + currentPage);
      console.log("totalPageCount :" + pgCnt);
      setMoviesToShow(result.data);
    });
  }

  const fetchActorsByLang = (lang, m_actorsCurrentPage) => {
    fetchActors(lang, m_actorsCurrentPage).then((result) => {
      console.log("actors count :" + result.count);
      const pgCnt = Math.ceil(result.count/actorsPerPage);
      console.log("pgCnt in fetchActorsByLang :" + pgCnt);
      setActorsTotalPageCount(pgCnt);
      console.log("actorsCurrentPage :" + actorsCurrentPage);
      console.log("actorsTotalPageCount :" + pgCnt);
      setActorsToShow(result.data);
      setSelectedActorObj(null); //set to null so that first object from search gets selected for searching movies
    });
  };

  const handleActorsPageChange = (newPage) => {
    console.log("currentPage in handleActorsPageChange :" + newPage);
    if ( actorSearched != null ) {
      handleActorSearch(actorSearched, newPage);
    } else {
      handleActorsPageChange2(newPage);
    }
  };

  const handleActorsPageChange2 = (m_actorsCurrentPage) => {
    // The actors current page is set to first page when Search is clicked everytime
    setActorsCurrentPage(m_actorsCurrentPage);
    fetchActorsByLang(lang, m_actorsCurrentPage);
  };

  const handleGenresPageChange = (newPage) => {
    console.log("currentPage in handleGenresPageChange :" + newPage);
    handleGenresPageChange2(newPage);
  };

  const handleGenresPageChange2 = (m_genresCurrentPage) => {

      // The genres current page is set to first page when Search is clicked everytime
      setGenresCurrentPage(m_genresCurrentPage);

      fetchGenres(lang, m_genresCurrentPage, genresPerPage).then((result) => {
          console.log("genres count :" + result.count);
          const pgCnt = Math.ceil(result.count/genresPerPage);
          setGenresTotalPageCount(pgCnt);
          console.log("genresCurrentPage :" + genresCurrentPage);
          console.log("genresTotalPageCount :" + pgCnt);
          setGenresToShow(result.data);
          setSelectedGenreObj(null); //set to null so that first object from search gets selected for searching movies
      });

  };

  const handleDirectorsPageChange = (newPage) => {
    console.log("currentPage in handleDirectorsPageChange :" + newPage);
    if ( directorSearched != null ) {
      handleDirectorSearch(directorSearched, newPage);
    } else {
      handleDirectorsPageChange2(newPage);
    }
  };

  const handleDirectorsPageChange2 = (m_directorsCurrentPage) => {

      // The directors current page is set to first page when Search is clicked everytime
      setDirectorsCurrentPage(m_directorsCurrentPage);

      fetchDirectors(lang, m_directorsCurrentPage, directorsPerPage).then((result) => {
          console.log("directors count :" + result.count);
          const pgCnt = Math.ceil(result.count/directorsPerPage);
          setDirectorsTotalPageCount(pgCnt);
          console.log("directorsCurrentPage :" + directorsCurrentPage);
          console.log("directorsTotalPageCount :" + pgCnt);
          setDirectorsToShow(result.data);
          setSelectedDirectorObj(null); //set to null so that first object from search gets selected for searching movies
      });

  };

  // Reset input state when the active tab changes
  useEffect(() => {
    console.log("Resetting currentPage from :" + currentPage + " to 0");
    setCurrentPage(0); // Reset the input field whenever the tab changes
  }, [category, selectedYear, selectedDecade]); // Dependency on activeTab to trigger reset

  useEffect(() => {
    console.log("useEffect with category :" + category + " called");

    if ( category !== "latestMoviesTab" ) { 
        setSearchedMovieName(null); // once you switch to any tab other than latestMoviesTab, the data searched on the latest movies tab should be cleared
    }

    if ( category === "latestMoviesTab" ) {
        console.log("UseEffect latestMoviesTab");
        if ( ((searchedMovieName == null) || (searchedMovieName.length == 0)) ) {
            const year = 0; // year = 0 means latest movies
            fetchMoviesByYear(year, currentPage);
        }
    } else if ( category === "moviesByYearTab" ) {
        console.log("UseEffect moviesByYearTab");
        fetchMoviesByYear(selectedYear, currentPage);
    }
    else if ( category === "moviesByActorTab" ) {
        console.log("UseEffect moviesByActorTab");
        if (actorsToShow == null) {
            fetchActorsByLang(lang, actorsCurrentPage);
        } else {
            console.log("UseEffect moviesByActorTab fetching movies");
            if ( selectedActorObj != null ) {
                fetchMoviesByActor(selectedActorObj.actorId, currentPage).then((result) => {
                  console.log("Data retrieved after fetchMoviesByActor");
                  console.log("count :" + result.count);
                  const pgCnt = Math.ceil(result.count/moviesPerPage);
                  console.log("pgCnt in fetchMoviesByActor :" + pgCnt);
                  setTotalPageCount(pgCnt);
                  console.log("currentPage :" + currentPage);
                  console.log("totalPageCount :" + totalPageCount);
                  setMoviesToShow(result.data);
                });
            }
        }
    }
    else if ( category === "moviesByDirectorTab" ) {
        console.log("UseEffect moviesByDirectorTab");
        if (directorsToShow == null) {
            fetchDirectors(lang, directorsCurrentPage, directorsPerPage).then((result) => {
                console.log("directors count :" + result.count);
                const pgCnt = Math.ceil(result.count/directorsPerPage);
                setDirectorsTotalPageCount(pgCnt);
                console.log("directorsCurrentPage :" + directorsCurrentPage);
                console.log("directorsTotalPageCount :" + pgCnt);
                setDirectorsToShow(result.data);
            });
        } else {
            console.log("UseEffect DirectorTab fetching movies");
            if ( selectedDirectorObj != null ) {
                fetchMoviesByDirector(selectedDirectorObj.directorId, currentPage, moviesPerPage).then((result) => {
                  console.log("Data retrieved after fetchMoviesByDirector");
                  console.log("count :" + result.count);
                  const pgCnt = Math.ceil(result.count/moviesPerPage);
                  console.log("pgCnt in fetchMoviesByDirector :" + pgCnt);
                  setTotalPageCount(pgCnt);
                  console.log("currentPage :" + currentPage);
                  console.log("totalPageCount :" + totalPageCount);
                  setMoviesToShow(result.data);
                  //setData(result);
                });
            }
        }
    }
    else if ( category === "moviesByGenreTab" ) {
        console.log("UseEffect moviesByGenreTab");
        if (genresToShow == null) {
            fetchGenres(lang, genresCurrentPage, genresPerPage).then((result) => {
                console.log("genres count :" + result.count);
                const pgCnt = Math.ceil(result.count/genresPerPage);
                setGenresTotalPageCount(pgCnt);
                console.log("genresCurrentPage :" + genresCurrentPage);
                console.log("genresTotalPageCount :" + pgCnt);
                setGenresToShow(result.data);
            });
        } else {
            console.log("UseEffect moviesByGenreTab fetching movies");
            if ( selectedGenreObj != null ) {
                fetchMoviesByGenre(selectedGenreObj.movieGenreId, currentPage, moviesPerPage).then((result) => {
                  console.log("Data retrieved after fetchMoviesByGenre");
                  console.log("count :" + result.count);
                  const pgCnt = Math.ceil(result.count/moviesPerPage);
                  console.log("pgCnt in fetchMoviesByGenre :" + pgCnt);
                  setTotalPageCount(pgCnt);
                  console.log("currentPage :" + currentPage);
                  console.log("totalPageCount :" + totalPageCount);
                  setMoviesToShow(result.data);
                });
            }
        }
    }
  }, [category, currentPage, selectedYear, selectedActorObj, selectedDirectorObj, selectedGenreObj]);

  const fetchMovies = async (year, m_currentPage) => {

    console.log('Inside fetchMovies for year :' + year);
    let jsonData = [];
    const url = `${config.apiUrl}/movies?year=${year}&lang=${lang}&page=${m_currentPage}&pageSize=${moviesPerPage}`;

    console.log("url in fetchMovies: " + url);

    try {
      const response = await fetch(url);
      jsonData = await response.json();
      console.log('After fetchMovies JsonData:' + jsonData);

      return jsonData;

    } catch (error) {
      jsonData = []; 
      console.error('Error fetching data:', error);
    } finally{
	setLoading(false);
    }
  };

  const fetchMoviesByActor = async (actorId, m_currentPage) => {

    console.log('Inside fetchMoviesByActor for actor :' + actorId);

    let jsonData = [];

    const url = `${config.apiUrl}/movies/actor?actorId=${actorId}&page=${m_currentPage}&pageSize=${moviesPerPage}`;
    //const url = `http://localhost:3001/movies/actor?actorId=${actorId}&page=${currentPage}&pageSize=${itemsPerPage}`;

    console.log("url in fetchMoviesByActor: " + url);

    try {
      const response = await fetch(url);
      jsonData = await response.json();

      return jsonData;

    } catch (error) {
      jsonData = []; 
      console.error('Error fetching data:', error);
    } finally{
	setLoading(false);
    }
  };

  const fetchMoviesByDirector = async (directorId, m_currentPage, m_moviesPerPage) => {

    console.log('Inside fetchMoviesByDirector for director :' + directorId);

    let jsonData = [];

    const url = `${config.apiUrl}/movies/director?directorId=${directorId}&page=${m_currentPage}&pageSize=${m_moviesPerPage}`;

    console.log("url in fetchMoviesByDirector: " + url);

    try {
      const response = await fetch(url);
      jsonData = await response.json();

      return jsonData;

    } catch (error) {
      jsonData = []; 
      console.error('Error fetching data:', error);
    } finally{
	setLoading(false);
    }
  };

  const fetchMoviesByGenre = async (genreId, m_currentPage, m_moviesPerPage) => {

    console.log('Inside fetchMoviesByGenre for genre :' + genreId);

    let jsonData = [];

    const url = `${config.apiUrl}/movies/genre?genreId=${genreId}&page=${m_currentPage}&pageSize=${m_moviesPerPage}`;

    console.log("url in fetchMoviesByGenre: " + url);

    try {
      const response = await fetch(url);
      jsonData = await response.json();

      return jsonData;

    } catch (error) {
      jsonData = []; 
      console.error('Error fetching data:', error);
    } finally{
	setLoading(false);
    }
  };

  // Helper function that returns the first year of the decade when called
  //
  function getCurrentYear(decade) {

    var currentYear = new Date().getFullYear();

    switch (decade) {
      case '2030s':
        break;
      case '2020s':
        currentYear = 2020;
        break;
      case '2010s':
        currentYear = 2010;
        break;
      case '2000s':
        currentYear = 2000;
        break;
      case '1990s':
        currentYear = 1990;
        break;
      case '1980s':
        currentYear = 1980;
        break;
      case '1970s':
        currentYear = 1970;
        //AF
        if ( lang === "Hindi" ) currentYear = 1975;
        break;
      case '1960s':
        currentYear = 1960;
        break;
      case '1950s':
        currentYear = 1950;
        break;
      case '1940s':
        currentYear = 1940;
        break;
      case '1930s':
        currentYear = 1930;
        break;
      default:
        currentYear = new Date().getFullYear();
        break;
    }

    return currentYear;

  }

  function YearPaging({decade, year}) {

    //AF var thisYear = new Date().getFullYear();
    var thisYear = 2024;
    var m_currentYear = getCurrentYear(decade);
    var yearsPerPage = 10; // Number of years to display per page

    //AF since movies are available from 1975 for Hindi lang
    if ( (lang === "Hindi") && (m_currentYear === 1975) ) yearsPerPage = 5;

    if (decade === "2020s") {
      yearsPerPage = thisYear - 2019; // we want everything from 2020 till current year
    }

    if (thisYear === year) year = m_currentYear;

    console.log("Year in YearPaging:" + year);
    console.log("selectedYear in YearPaging:" + selectedYear);

    //setSelectedYear(year);

    console.log("Inside YearPaging currentYear :" + m_currentYear);
    console.log("Inside YearPaging year :" + year);
    console.log("YearPaging decade :" + decade);

    const onYearChange = (year) => {
      console.log('onYearChange clicked year:' + year);
      setSelectedYear(year);
      console.log("onYearChange selectedYear :" + selectedYear);
/*
      fetchMoviesToShow(2000, currentPage, itemsPerPage).then((result) => {
        setMoviesToShow(result.movies); 
      });
*/
    };

    // Calculate the range of years to display based on the selected year
    //const startYear = (Math.ceil(selectedYear / yearsPerPage) - 1) * yearsPerPage + 3;
    const startYear = m_currentYear;
    //const endYear = startYear + yearsPerPage - 1;

    // Generate an array of years for the current page
    const yearsToShow = Array.from({ length: yearsPerPage }, (_, index) => startYear + index);

    console.log("Years to show :" + yearsToShow);
    console.log("Selected Year :" + selectedYear);

    return (
      <div>
{/* Uncomment if needed later
      <div style={{paddingLeft:0, display:'flex', flexWrap:'wrap'}}>
        <p>Selected Year: {selectedYear}</p>
      </div>
*/}
      <div>
        <YearPagination selectionYear={selectedYear} years={yearsToShow} onYearChange={onYearChange}/>
        {/* Other components or content */}
      </div>
      </div>
    );

  }

  const fetchActors = async (m_lang, m_actorsCurrentPage) => {

    console.log('Inside fetchActors for lang :' + lang);

    let jsonData = [];

    const url = `${config.apiUrl}/actors?lang=${m_lang}&page=${m_actorsCurrentPage}&pageSize=${actorsPerPage}`;

    console.log("url in fetchActors: " + url);

    try {
      const response = await fetch(url);
      jsonData = await response.json();
      console.log('After fetchActors JsonData:' + jsonData);

      return jsonData;

    } catch (error) {
      jsonData = []; 
      console.error('Error fetching data:', error);
    } finally{
	setLoading(false);
    }
  };

  const fetchDirectors = async (m_lang, m_directorsCurrentPage, m_directorsPerPage) => {

    console.log('Inside fetchDirectors for lang :' + lang);

    let jsonData = [];

    const url = `${config.apiUrl}/directors?lang=${m_lang}&page=${m_directorsCurrentPage}&pageSize=${m_directorsPerPage}`;

    console.log("url in fetchDirectors: " + url);

    try {
      const response = await fetch(url);

      jsonData = await response.json();
      console.log('After fetchDirectors JsonData:' + jsonData);

      return jsonData;

    } catch (error) {
      jsonData = []; 
      console.error('Error fetching data:', error);
    } finally{
	setLoading(false);
    }
  };

  const fetchGenres = async (m_lang, m_genresCurrentPage, m_genresPerPage) => {

    console.log('Inside fetchGenres for lang :' + lang);

    let jsonData = [];

    const url = `${config.apiUrl}/genres?lang=${m_lang}&page=${m_genresCurrentPage}&pageSize=${m_genresPerPage}`;

    console.log("url in fetchGenres: " + url);

    try {
      const response = await fetch(url);
      jsonData = await response.json();
      console.log('After fetchGenres JsonData:' + jsonData);

      return jsonData;

    } catch (error) {
      jsonData = []; 
      console.error('Error fetching data:', error);
    } finally{
	setLoading(false);
    }
  };

  const fetchActorSearch = async (m_actorName, m_actorsCurrentPage) => {

    console.log('Inside fetchActorSearch for actor :' + m_actorName +' lang :' + lang);

    let jsonData = [];

    const url = `${config.apiUrl}/actors/search?lang=${lang}&actor=${m_actorName}&page=${m_actorsCurrentPage}&pageSize=${actorsPerPage}`;

    console.log("url in fetchActorSearch: " + url);

    try {
      const response = await fetch(url);
      jsonData = await response.json();
      console.log('After fetchActorSearch JsonData:' + jsonData);

      return jsonData;

    } catch (error) {
      jsonData = []; 
      console.error('Error fetching data:', error);
    } finally{
	setLoading(false);
    }
  };

  const fetchDirectorSearch = async (m_directorName, m_directorsCurrentPage) => {

    console.log('Inside fetchDirectorSearch for director :' + m_directorName +' lang :' + lang);

    let jsonData = [];

    const url = `${config.apiUrl}/directors/search?lang=${lang}&director=${m_directorName}&page=${m_directorsCurrentPage}&pageSize=${directorsPerPage}`;

    console.log("url in fetchDirectorSearch: " + url);

    try {
      const response = await fetch(url);
      jsonData = await response.json();
      console.log('After fetchDirectorSearch JsonData:' + jsonData);

      return jsonData;

    } catch (error) {
      jsonData = []; 
      console.error('Error fetching data:', error);
    } finally{
	setLoading(false);
    }
  };

  const fetchMovieSearch = async (m_movieName, m_movieCurrentPage) => {

    console.log('Inside fetchMovieSearch for movie :' + m_movieName +' lang :' + lang);

    let jsonData = [];

    const url = `${config.apiUrl}/movies/search?lang=${lang}&name=${m_movieName}&page=${m_movieCurrentPage}&pageSize=${moviesPerPage}`;

    console.log("url in fetchMovieSearch: " + url);

    try {
      const response = await fetch(url);
      jsonData = await response.json();
      console.log('After fetchMovieSearch JsonData:' + jsonData);

      return jsonData;

    } catch (error) {
      jsonData = []; 
      console.error('Error fetching data:', error);
    } finally{
	setLoading(false);
    }
  };

  function DecadePaging() {

    console.log("In Decades:");

    const onDecadeChange = (decade) => {
      console.log('onDecadeChange clicked' + decade);
      setSelectedDecade(decade);
      let year = getCurrentYear(decade); // this is the default year when decade is clicked

      console.log("Year in onDecadeChange =" + year);
      setSelectedYear(year);

    };

    // Generate an array of years for the current page
    //AF const decadesToShow = ["2020s", "2010s", "2000s", "1990s", "1980s", "1970s", "1960s","1950s", "1940s", "1930s"];
    var decadesToShow = ["2020s", "2010s", "2000s"];

    if (lang === "Hindi") decadesToShow = ["2020s", "2010s", "2000s", "1990s", "1980s", "1970s"];

    console.log("decadesToShow :" + decadesToShow);

    return (
      <div>
      {decadesToShow ? (
	<div>
{/* Uncomment if needed later
          <div style={{paddingLeft:0, display:'flex', flexWrap:'wrap'} }>
            <p>Selected Decade: {selectedDecade}</p>
          </div>
*/}
          <div style={{display:'flex', overflowX:'scroll', whiteSpace:'nowrap'}}>
            <DecadePagination selectedDecade={selectedDecade} decades={decadesToShow} onDecadeChange={onDecadeChange}/>
            {/* Other components or content */}
          </div> 
          <div>
              <YearPaging decade={selectedDecade} year={selectedYear}/>
          </div>
	    </div>
	    ) : (
            <ComingSoon lang={selectedYear}/>
          )}
      </div>
    );
  }

  function ActorPaging() {

    console.log("lang in ActorPaging:" + lang);

    const onActorChange = (actorObj) => {
      console.log('onActorChange clicked actorName=' + actorObj.actorName + ' actorId=' + actorObj.actorId);
      //TBD: add fetchData by Actor API
      //fetchData(actor).then((result)

      //setSelectedActorId(actorId);
      //setSelectedActor(actorName);

      setSelectedActorObj(actorObj);
      //setCurrentPage(0); // setting it to first page
    };

    console.log("actorsToShow :" + actorsToShow);

    //const actorsToShow = helperParseActors(actorsJSONArray);

    if (selectedActorObj == null) {
        if (actorsToShow != null) {
            if (actorsToShow[0] != null) {
              //setSelectedActorId(actorsToShow[0].actorId);
              //setSelectedActor(actorsToShow[0].actorName);
              setSelectedActorObj(actorsToShow[0]);
            }
        }
    }

    return (
      <div>
      {actorsToShow ? (
	<div>

{/* Uncomment if needed later
          <div style={{paddingLeft:20, display:'flex', flexWrap:'wrap'} }>
            <p>Selected Actor: {selectedActorObj ? selectedActorObj.actorName : "Actor not found"}</p>
          </div>
*/}

          <div style={{display:'flex', overflowX:'scroll', whiteSpace:'nowrap'}}>

              <div className="prev-next-actors-buttons">
                  <button style={{fontSize:'95px', border:'none', background:'none'}} onClick={() => handleActorsPageChange(actorsCurrentPage - 1)} disabled={actorsCurrentPage <= 0}>
                     	&laquo;
                  </button>
              </div>

              <ActorPagination selectedActor={selectedActorObj} actors={actorsToShow} onActorChange={onActorChange}/>
                    {/* Other components or content */}

              <div className="prev-next-actors-buttons">
                  <button style={{fontSize:'95px', border:'none', background:'none'}} onClick={() => {handleActorsPageChange(actorsCurrentPage + 1)}} disabled={(actorsTotalPageCount === 0) || (actorsCurrentPage === actorsTotalPageCount - 1)}>
                     	&raquo;
                  </button>
              </div>

          </div> 
	</div>
	    ) : (
            <ComingSoon lang={selectedYear}/>
          )}
      </div>
    );
  }

  function DirectorPaging() {

    console.log("lang in DirectorPaging:" + lang);

    const onDirectorChange = (directorObj) => {
      console.log('onDirectorChange clicked directorName=' + directorObj.directorName + ' directorId=' + directorObj.directorId);

      setCurrentPage(0); // setting it to first page
      setSelectedDirectorObj(directorObj);
    };

    console.log("directorsToShow :" + directorsToShow);


    if (selectedDirectorObj == null || selectedDirectorObj == '') {
        if (directorsToShow != null) {
            if (directorsToShow[0] != null) {
              setSelectedDirectorObj(directorsToShow[0]);
            }
        }
    }

    return (
      <div>
      {directorsToShow ? (
	<div>
          <div>
          </div>
{/* Uncomment if needed later
          <div style={{paddingLeft:20, display:'flex', flexWrap:'wrap'} }>
            <p>Selected Director: {selectedDirectorObj ? selectedDirectorObj.directorName : "Director not found"}</p> 
          </div>
*/}

          <div style={{display:'flex', overflowX:'scroll', whiteSpace:'nowrap'}}>

              <div className="prev-next-actors-buttons">
                  <button onClick={() => handleDirectorsPageChange(directorsCurrentPage - 1)} disabled={directorsCurrentPage <= 0}>
                     Prev
                  </button>
              </div>

              <DirectorPagination selectedDirector={selectedDirectorObj} directors={directorsToShow} onDirectorChange={onDirectorChange}/>
                    {/* Other components or content */}

              <div className="prev-next-actors-buttons">
                  <button onClick={() => {handleDirectorsPageChange(directorsCurrentPage + 1)}} disabled={directorsTotalPageCount === 0 || (directorsCurrentPage === directorsTotalPageCount - 1)}>
                     Next
                  </button>
              </div>

          </div> 
	</div>
	    ) : (
            <ComingSoon lang={selectedYear}/>
          )}
      </div>
    );
  }

  function GenrePaging() {

    console.log("lang in GenrePaging:" + lang);

    const onGenreChange = (genreObj) => {
      console.log('onGenreChange clicked genreName=' + genreObj.movieGenreName + ' genreId=' + genreObj.movieGenreId);

      setCurrentPage(0); // setting it to first page
      setSelectedGenreObj(genreObj);
    };

    console.log("genresToShow :" + genresToShow);

    if (selectedGenreObj == null || selectedGenreObj == '') {
        if (genresToShow != null) {
            if (genresToShow[0] != null) {
              setSelectedGenreObj(genresToShow[0]);
            }
        }
    }

    return (
      <div>
      {genresToShow ? (
	<div>
          <div>
          </div>
{/* Uncomment if needed later
          <div style={{paddingLeft:20, display:'flex', flexWrap:'wrap'} }>
            <p>Selected Genre: {selectedGenreObj ? selectedGenreObj.movieGenreName : "Genre not found"}</p> 
          </div>
*/}

          <div style={{display:'flex', overflowX:'scroll', whiteSpace:'nowrap'}}>

              <div className="prev-next-actors-buttons">
                  <button onClick={() => handleGenresPageChange(genresCurrentPage - 1)} disabled={genresCurrentPage <= 0}>
                     Prev
                  </button>
              </div>

              <GenrePagination selectedGenre={selectedGenreObj} genres={genresToShow} onGenreChange={onGenreChange}/>
                    {/* Other components or content */}

              <div className="prev-next-actors-buttons">
                  <button onClick={() => {handleGenresPageChange(genresCurrentPage + 1)}} disabled={genresTotalPageCount === 0 || (genresCurrentPage === genresTotalPageCount - 1)}>
                     Next
                  </button>
              </div>

          </div> 
	</div>
	    ) : (
            <ComingSoon lang={selectedYear}/>
          )}
      </div>
    );
  }

  return (
<div>
    <div>
      {
	((props.category === 'latestMoviesTab') && 
	( 
                <div>
                        <SearchMovie onClick={handleMovieSearch} text={searchedMovieName} onClearMovieSearch={clearMovieSearch}/>
                </div>
	)) 
        ||
	((props.category === 'moviesByYearTab') && 
	( 
			<DecadePaging/>
	)) 
        ||
	((props.category === 'moviesByActorTab') && 
	( 
		<div>
			<SearchActor onClick={handleActorSearch} text={searchedActorName} onClearActorSearch={clearActorSearch}/>
			<ActorPaging/>
		</div>
	))
        ||
	((props.category === 'moviesByDirectorTab') && 
	( 
		<div>
			<SearchDirector onClick={handleDirectorSearch} text={searchedDirectorName} onClearDirectorSearch={clearDirectorSearch}/>
			<DirectorPaging/>
		</div>
	))
        ||
	((props.category === 'moviesByGenreTab') && 
	( 
			<GenrePaging/>
	))
      }
      {moviesToShow && ((props.category === 'latestMoviesTab') | (props.category === 'moviesByYearTab') | (props.category === 'moviesByActorTab') | (props.category === 'moviesByDirectorTab' | (props.category === 'moviesByGenreTab'))) ? (
	  <div>
            <ul style={{paddingLeft:50, display:'flex', flexWrap:'wrap'}}>
            {moviesToShow.map((item) => 
	      <li key={item.movieId} style={{listStyle:'none', padding:10}}>
		  <MovieImageComponent data_item={item} />
	      </li>
            )}
            </ul>
       </div>
      ) : 
            ((props.category === 'moviesByYearTab') && <ComingSoon lang={selectedYear}/>)
            ||
            ((props.category === 'moviesByActorTab') && (selectedActorObj != null) && <ComingSoon lang={selectedActorObj.actorName}/>)
            ||
            ((props.category === 'moviesByGenreTab') && (selectedGenreObj != null) && <ComingSoon lang={selectedGenreObj.genreName}/>)
            ||
            ((props.category === 'latestMoviesTab') && <ComingSoon lang={lang}/>)
            ||
            ((props.category === 'moviesByDirectorTab') && <ComingSoon lang="Movies By Director"/>)
      }
            <div className="prev-next-buttons">
               <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>
                 Prev
               </button>
               <button onClick={() => {console.log("currentPage=" + currentPage + " totalPageCount =" + totalPageCount); handlePageChange(currentPage + 1)}} disabled={ (totalPageCount === 0) || (currentPage === totalPageCount - 1)}>
                 Next
               </button>
	    </div>
    </div>
</div>
  );
}

export default Movies;

